import React from 'react';
import { Button, ButtonPriority, InputDialog, ThreeDotsLoader } from 'wix-ui-tpa';

import { useBi, useEnvironment, useErrorMonitor, useFedopsLogger, useTranslation } from '@wix/yoshi-flow-editor';
import { connect } from '../../../../common/components/runtime-context';
import { getDetails, getLanguage, getUpmModalSubscription, isUpmModalOpen } from '../../selectors';
import { RootState } from '../../state';
import { PaymentsWidget, PaymentsWidgetAPI } from '@wix/cashier-payments-widget/lazy';
import { IActionCreators } from '../../../../types/internal-types';
import { Subscription } from '@wix/ambassador-billing-v1-subscription/types';
import { classes, st } from './UpmModal.st.css';
import classNames from 'classnames';
import { Interactions } from '../../../../types/interactions';
import { UpmFlowStatus } from '../../constants';
import { aClickCancelUpmEvent, aClickSubmitUpmEvent } from '@wix/bi-logger-my-subscriptions/v2';
import { SavedCreditCardDetails } from '@wix/ambassador-cashier-pay-v2-payment-method/types';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;
interface Props {
  subscription: Subscription;
}
export const UpmModal = ({
  isOpen,
  subscription,
  actionCreators,
  language,
  IdentityParams,
  visitorId,
  upmFlowStatus,
  subscriptionExtraDetails,
}: RuntimeProps & Props) => {
  const { isMobile } = useEnvironment();
  const biLogger = useBi();

  const [shouldDisableSubmitBtn, setShouldDisableSubmitBtn] = React.useState(true);
  const paymentsWidgetApi = React.useRef<PaymentsWidgetAPI | undefined>();
  const { t } = useTranslation();
  const { msid, instance, siteOwnerId, sessionId } = IdentityParams;
  const fedopsLogger = useFedopsLogger();
  const errorMonitor = useErrorMonitor();
  const isValidationError = (e: any) => e?.isValid === false;

  const upmModalSubmit = async () => {
    biLogger.report(aClickSubmitUpmEvent({ subscriptionId: subscription.id! }));
    fedopsLogger.interactionStarted(Interactions.SubscriptionUpm);
    if (upmFlowStatus === UpmFlowStatus.INIT) {
      actionCreators.setUpmFlowStatus(UpmFlowStatus.PENDING);
      const savedPaymentMethodResponse:
        | {
            paymentAgreementId: string;
          }
        | undefined = await paymentsWidgetApi.current?.getSavedPaymentMethod().catch((e) => {
        if (!isValidationError(e)) {
          errorMonitor.addBreadcrumb({
            message: 'error in payments side when trying to create/get payment method on upm modal',
          });
          errorMonitor.captureException(e);
        }
        fedopsLogger.interactionEnded(Interactions.SubscriptionUpm);
        actionCreators.setUpmFlowStatus(UpmFlowStatus.INIT);
        return undefined;
      });
      if (savedPaymentMethodResponse?.paymentAgreementId) {
        const paymentMethodId = savedPaymentMethodResponse?.paymentAgreementId || '';
        actionCreators.submitUpm({
          subscriptionId: subscription.id!,
          paymentMethodId,
        });
      } else {
        errorMonitor.captureMessage("payments api didn't return paymentMethodId in the response");
        fedopsLogger.interactionEnded(Interactions.SubscriptionUpm);
      }
    } else {
      fedopsLogger.interactionEnded(Interactions.SubscriptionUpm);
    }
  };

  const onClickCloseModal = (referral: string) => {
    biLogger.report(aClickCancelUpmEvent({ referral, subscriptionId: subscription.id! }));
    actionCreators.closeUpmModal();
  };

  const getModalSubtitle = () => {
    const shouldShowModalSubtitle =
      subscriptionExtraDetails.paymentMethodDetails?.savedCreditCardDetails?.network &&
      subscriptionExtraDetails.paymentMethodDetails?.savedCreditCardDetails?.lastFourDigits &&
      subscription.name &&
      !shouldDisableSubmitBtn;
    if (shouldShowModalSubtitle) {
      return t('app.checkout-form.upm.subtitle-info', {
        subscriptionName: subscription.name,
        paymentMethod: subscriptionExtraDetails.paymentMethodDetails?.savedCreditCardDetails?.network,
        cardNumber: subscriptionExtraDetails.paymentMethodDetails?.savedCreditCardDetails?.lastFourDigits,
      });
    }
    return undefined;
  };
  const onSelectPaymentMethod = (
    _paymentMethodId: string,
    details?: {
      savedPaymentMethodId?: string;
      paymentAgreementId?: string;
      savedCreditCardDetails?: SavedCreditCardDetails;
    },
  ) => {
    if (
      details?.paymentAgreementId &&
      details?.paymentAgreementId === subscription.billingSettings!.paymentMethod?.id
    ) {
      setShouldDisableSubmitBtn(true);
    } else {
      setShouldDisableSubmitBtn(false);
    }
  };
  return (
    <div className={classNames(st(classes.root, { desktop: !isMobile }))}>
      <InputDialog
        title={t('app.settings.default.update-payment.text')}
        subtitle={getModalSubtitle()}
        data-hook="upm-modal"
        isOpen={isOpen}
        onClose={() => {
          onClickCloseModal('exit_window');
        }}
        fullscreen={isMobile}
        customFooter={
          <div className={classes.modalFooter}>
            <Button
              onClick={upmModalSubmit}
              fullWidth={isMobile}
              data-hook="upm-submit-button"
              upgrade
              disabled={shouldDisableSubmitBtn}
            >
              {upmFlowStatus === UpmFlowStatus.PENDING ? (
                <ThreeDotsLoader className={classes.threeDotsLoader} />
              ) : (
                t('app.checkout-form.pay-now-upm.submit.button')
              )}
            </Button>
            {!isMobile && (
              <Button
                onClick={() => onClickCloseModal('cancel_button')}
                data-hook="upm-cancel-button"
                priority={ButtonPriority.basicSecondary}
                disabled={upmFlowStatus === UpmFlowStatus.PENDING}
                upgrade
              >
                {t('app.checkout-form.pay-now-upm.cancel.button')}
              </Button>
            )}
          </div>
        }
      >
        <div className={classes.paymentsWidgetContainer} data-hook="payments-widget-container">
          <PaymentsWidget
            configuration={{
              locale: language,
              appId: IdentityParams.appDefinitionId,
              appInstanceId: IdentityParams.appInstanceId,
              appInstance: instance,
              amount: '0',
              currency: subscription.billingSettings!.currency!,
              msid: msid || '',
              siteOwnerId,
              visitorId,
              viewMode: 'Site',
              isSignedInUser: true,
              sessionId,
            }}
            autoSelectedPaymentAgreementId={subscription.billingSettings!.paymentMethod?.id}
            externalSubmitButton // ability to show our own button
            onCrash={() => {
              setShouldDisableSubmitBtn(true);
              errorMonitor.captureMessage('Payments Widget Crashed On UPM');
            }} // Payments will show error message,
            onApiInit={(api) => {
              paymentsWidgetApi.current = api;
              setShouldDisableSubmitBtn(false);
            }}
            // @ts-expect-error
            paymentMethodChanged={onSelectPaymentMethod}
            isSaveCCEnabled={true}
            allowRecurringPaymentOnly
            allowSaveableMethodsOnly
          />
          <div className={classes.afterWidgetPadding} />
        </div>
      </InputDialog>
    </div>
  );
};

const mapRuntimeToProps = (state: RootState, _: {}, actionCreators: IActionCreators) => {
  return {
    isOpen: isUpmModalOpen(state),
    subscription: getUpmModalSubscription(state),
    actionCreators,
    language: getLanguage(state),
    IdentityParams: state.IdentityParams,
    visitorId: state.user?.id,
    upmFlowStatus: state.upmModal.upmFlowStatus,
    subscriptionExtraDetails: getDetails(state, state.upmModal.subscriptionId!),
  };
};

export default connect(mapRuntimeToProps)(UpmModal);
